export const BlokHeading = () => import('../../components/storyblok/blok/Heading.vue' /* webpackChunkName: "components/blok-heading" */).then(c => wrapFunctional(c.default || c))
export const LayoutConfig = () => import('../../components/storyblok/layout/Config.vue' /* webpackChunkName: "components/layout-config" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooter = () => import('../../components/storyblok/layout/Footer.vue' /* webpackChunkName: "components/layout-footer" */).then(c => wrapFunctional(c.default || c))
export const PageConstructor = () => import('../../components/storyblok/page/Constructor.vue' /* webpackChunkName: "components/page-constructor" */).then(c => wrapFunctional(c.default || c))
export const PageProject = () => import('../../components/storyblok/page/Project.vue' /* webpackChunkName: "components/page-project" */).then(c => wrapFunctional(c.default || c))
export const BlokAboutSlider = () => import('../../components/storyblok/blok/about/Slider.vue' /* webpackChunkName: "components/blok-about-slider" */).then(c => wrapFunctional(c.default || c))
export const BlokAboutTeam = () => import('../../components/storyblok/blok/about/Team.vue' /* webpackChunkName: "components/blok-about-team" */).then(c => wrapFunctional(c.default || c))
export const BlokContactsImage = () => import('../../components/storyblok/blok/contacts/Image.vue' /* webpackChunkName: "components/blok-contacts-image" */).then(c => wrapFunctional(c.default || c))
export const BlokProjectGallery = () => import('../../components/storyblok/blok/project/Gallery.vue' /* webpackChunkName: "components/blok-project-gallery" */).then(c => wrapFunctional(c.default || c))
export const BlokProjectVimeo = () => import('../../components/storyblok/blok/project/Vimeo.vue' /* webpackChunkName: "components/blok-project-vimeo" */).then(c => wrapFunctional(c.default || c))
export const UiAboutTeamItem = () => import('../../components/storyblok/ui/about/TeamItem.vue' /* webpackChunkName: "components/ui-about-team-item" */).then(c => wrapFunctional(c.default || c))
export const UiProjectGalleryItem = () => import('../../components/storyblok/ui/project/GalleryItem.vue' /* webpackChunkName: "components/ui-project-gallery-item" */).then(c => wrapFunctional(c.default || c))
export const BaseLinkWr = () => import('../../components/base/LinkWr.vue' /* webpackChunkName: "components/base-link-wr" */).then(c => wrapFunctional(c.default || c))
export const BaseRichtext = () => import('../../components/base/Richtext.vue' /* webpackChunkName: "components/base-richtext" */).then(c => wrapFunctional(c.default || c))
export const UiBack = () => import('../../components/ui/Back.vue' /* webpackChunkName: "components/ui-back" */).then(c => wrapFunctional(c.default || c))
export const UiHeading = () => import('../../components/ui/Heading.vue' /* webpackChunkName: "components/ui-heading" */).then(c => wrapFunctional(c.default || c))
export const UiLazyImage = () => import('../../components/ui/LazyImage.vue' /* webpackChunkName: "components/ui-lazy-image" */).then(c => wrapFunctional(c.default || c))
export const RichtextBlocksImage = () => import('../../components/richtext/blocks/Image.vue' /* webpackChunkName: "components/richtext-blocks-image" */).then(c => wrapFunctional(c.default || c))
export const RichtextMarksLink = () => import('../../components/richtext/marks/Link.vue' /* webpackChunkName: "components/richtext-marks-link" */).then(c => wrapFunctional(c.default || c))
export const UiProjectsFilter = () => import('../../components/ui/projects/Filter.vue' /* webpackChunkName: "components/ui-projects-filter" */).then(c => wrapFunctional(c.default || c))
export const UiProjectsFilterDropdownToggle = () => import('../../components/ui/projects/FilterDropdownToggle.vue' /* webpackChunkName: "components/ui-projects-filter-dropdown-toggle" */).then(c => wrapFunctional(c.default || c))
export const UiProjectsFilterItem = () => import('../../components/ui/projects/FilterItem.vue' /* webpackChunkName: "components/ui-projects-filter-item" */).then(c => wrapFunctional(c.default || c))
export const UiProjectsTableItem = () => import('../../components/ui/projects/TableItem.vue' /* webpackChunkName: "components/ui-projects-table-item" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
